import Axios from 'axios';
import { BasketLineRequestModel } from '~/dataModels/BasketModel/BasketLineRequestModel';
import { BasketLineResponseModel } from '~/dataModels/BasketModel/BasketLineResponseModel';
import { BasketPlateResponseModel } from '~/dataModels/BasketModel/BasketPlateResponseModel';
import { BasketResponseSummary } from '~/dataModels/BasketModel/BasketResponseModel';
import { BasketApiUrls } from '~/utils/BasketApiUrls';
// import {
//   getCheckout,
//   getEmptyCheckout,
// } from '~/stateManagement/localStorage/checkout';
// import { BasketAttributeModel } from '~/dataModels/BasketModel/BasketRequestModel';

export const getNewBasketIdService = async (apiUrl: string) => {
  const url = BasketApiUrls.getBasketIdUrl(apiUrl);
  const result = await Axios.post(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result.data.Data;
};

export const checkBasketResponseService = async (
  apiUrl: string,
  basketId: string,
) => {
  const url = BasketApiUrls.checkBasketResponseUrl(apiUrl, basketId);
  const result = await Axios.post(url, basketId);
  const data = result.data;
  if (data.Success) {
    return data.Data;
  }
};

export const getBasketResponseService = async (
  apiUrl: string,
  basketId: string,
) => {
  const url = BasketApiUrls.basketResponseUrl(apiUrl, basketId);
  const result = await Axios.get(url);
  const data = result.data;
  console.log('get basket response data: ', data);
  if (data.Success) {
    const basketLines = data.Data.LineItems.map((basketLine: any) => {
      const plate: BasketPlateResponseModel = {
        rangeId: basketLine.RangeId,
        rangeName: basketLine.RangeName,
        designId: basketLine.PlateDesignId,
        designName: basketLine.DesignName,
        foregroundColorId: basketLine.ForegroundColourId,
        foregroundColorName: basketLine.ForegroundColourName,
        size: {
          id: basketLine.Size.PlateSizeId,
          name: basketLine.Size.Name,
          width_mm: basketLine.Size.Width_mm,
          height_mm: basketLine.Size.Height_mm,
        },
      };
      const line: BasketLineResponseModel = {
        basketLineId: basketLine.LineId,
        combination: basketLine.Combination,
        combinationLayout: basketLine.CombinationLayout,
        transactionTypeName: basketLine.TransactionTypeName,
        transactionTypeId: basketLine.TransactionTypeId,
        vehicleTypeId: basketLine.VehicleTypeId,
        vehicleTypeName: basketLine.VehicleTypeName,
        frontPlate: plate,
        backPlate:
          basketLine.DualPlateDesignId !== null
            ? {
                rangeId: basketLine.DualPlateRangeId,
                rangeName: basketLine.DualPlateRangeName,
                designId: basketLine.DualPlateDesignId,
                designName: basketLine.DualPlateDesignName,
                foregroundColorId: basketLine.DualPlateForegroundColourId,
                foregroundColorName: basketLine.DualPlateForegroundColourName,
                size: {
                  id: basketLine.DualPlateSize.PlateSizeId,
                  name: basketLine.DualPlateSize.Name,
                  width_mm: basketLine.DualPlateSize.Width_mm,
                  height_mm: basketLine.DualPlateSize.Height_mm,
                },
              }
            : plate,
        topCaption:
          basketLine.CaptionTopText !== ''
            ? {
                captionText: basketLine.CaptionTopText,
                captionColourName: basketLine.CaptionTopColourName,
                captionColourId: basketLine.CaptionTopColourId,
              }
            : undefined,
        bottomCaption:
          basketLine.CaptionBottomText !== ''
            ? {
                captionText: basketLine.CaptionBottomText,
                captionColourName: basketLine.CaptionBottomColourName,
                captionColourId: basketLine.CaptionBottomColourId,
              }
            : undefined,
        priceInclGst: basketLine.PriceIncGST,
        gst: basketLine.GST,
        discountedGst: basketLine.DiscountedGST,
        discountedPriceInclGst: basketLine.DiscountedPriceIncGST,
        stillAvailable: basketLine.StillAvailable,
        isGift: basketLine.IsGift,
      };
      return line;
    });
    const summary: BasketResponseSummary = {
      itemTotal: data.Data.ItemTotal,
      discount: data.Data.Discount,
      finalTotal: data.Data.FinalTotal,
      gst: data.Data.GST,
      promotionCode: data.Data.PromotionCode
        ? data.Data.PromotionCode
        : undefined,
      allowPaymentLayby: data.Data.ALlowPaymentLayBy,
    };
    return {
      result: {
        basket: {
          basketId: basketId,
          basketLines: basketLines,
          summary: summary,
        },
        // checkout: data.Data.Checkout ? getCheckout(data.Data.Checkout) : getEmptyCheckout()
      },
      success: true,
    };
  } else {
    return {
      success: false,
    };
  }
};

export const removeBasketPromotion = async (
  apiUrl: string,
  basketId: string,
) => {
  const url = BasketApiUrls.removeBasketPromoUrl(apiUrl, basketId);
  const result = await Axios.post(url);
  const data = result.data;
  if (data.Success) {
    return true;
  } else {
    return false;
  }
};

export const addBasketLineItemService = async (
  apiUrl: string,
  basketId: string,
  basketLine: BasketLineRequestModel,
) => {
  const url = BasketApiUrls.saveBasketLineItemUrl(apiUrl, basketId);
  const result = await Axios.post(url, JSON.stringify(basketLine), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  console.log('add basket line result: ', result);
  return result.data.Data;
};

export const deleteBasketLineService = async (
  apiUrl: string,
  basketId: string,
  basketLineId: string,
) => {
  const url = BasketApiUrls.deleteBasketLineItemUrl(
    apiUrl,
    basketId,
    basketLineId,
  );
  const isDeleteSuccessful = await Axios.delete(url)
    .then((result) => {
      return result.data.Data;
    })
    .catch(() => {
      return false;
    });
  return isDeleteSuccessful;
};

export const validatePromoService = async (
  apiUrl: string,
  promoCode: string,
  basketId: string,
) => {
  const url = BasketApiUrls.validatePromotionUrl(
    apiUrl,
    btoa(promoCode),
    basketId,
  );

  const result = await Axios.get(url);

  return result.data.Data;
};

export const checkPromoService = async (
  apiUrl: string,
  promoCode: string,
  basketId: string,
) => {
  const url = BasketApiUrls.checkPromotionUrl(
    apiUrl,
    btoa(promoCode),
    basketId,
  );

  const result = await Axios.get(url);

  return result.data;
};

export const checkDesignForPromoService = async (
  apiUrl: string,
  promoCode: string,
  basketId: string,
) => {
  const url = BasketApiUrls.checkDesignForPromotionUrl(
    apiUrl,
    btoa(promoCode),
    basketId,
  );

  const result = await Axios.get(url);

  return result.data;
};

export const getCheckoutResponseService = async (
  apiUrl: string,
  basketId: string,
) => {
  const url = BasketApiUrls.checkoutResponseUrl(apiUrl, basketId);
  const result = await Axios.get(url);
  const data = result.data;
  if (data.Success) {
    return {
      checkout: data.Data,
      success: true,
    };
  } else {
    return {
      success: false,
    };
  }
};
